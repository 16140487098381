<template>
	<div>
		<!-- 提现弹框 -->
		<div class="editPaw">
			<el-dialog title="余额提现" :visible.sync="dialogVisible2">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
					<div class="ipt_box" style="margin-bottom: 15px">
						当前余额：<span style="margin-left: 10px">666.00元</span>
					</div>
					<el-form-item label="提现金额：" prop="jine">
						<el-input v-model="ruleForm.jine" type="password" placeholder="请输入提现金额" name="jine" />
					</el-form-item>
					<!-- 提现账户： -->
					<el-form-item label="提现账户：" prop="zhanghu">
						<el-input v-model="ruleForm.zhanghu" type="password" placeholder="请输入支付宝账号" name="zhanghu" />
					</el-form-item>
					<!-- 支付密码： -->
					<el-form-item label="提现账户：" prop="mima">
						<el-input v-model="ruleForm.mima" type="password" placeholder="请输入支付密码" name="mima" />
					</el-form-item>
				</el-form>
				<div slot="footer" class="edit_dailog">
					<div @click="submitForm('ruleForm')">确 定</div>
					<div @click="cancle">取消</div>
				</div>
			</el-dialog>
		</div>
		<div class="yue_body">
			<div class="yue_top">
				<div class="yue_left">
					<div class="tit">您当前可用余额：</div>
					<div class="yue">{{ yue }}</div>
				</div>
				<div class="tue_right">
					<!-- <div class="btn" @click="changeCurrent(0)" :class="{current:current==0}">充值</div> -->
					<!-- @click="dialogVisible2 = true" -->
					<div class="btn" @mouseenter="current = 1" @mouseleave="current = 0"
						:class="{ current: current == 1 }">
						提现
					</div>
				</div>
			</div>
			<!-- 余额明细 -->
			<div class="mingxi_tabs">
				<div class="ming_left">余额明细</div>
				<!-- <div class="ming_right">
          <div
            class="mingTabs"
            :class="{ current2: current2 == index }"
            v-for="(item, index) in tabsList2"
            :key="index"
            @click="changeCurrent2(index)"
          >
            {{ item }}
          </div>
        </div> -->
			</div>
			<!-- 余额表格 -->
			<el-table :data="tableData" stripe style="width: 100%"
				:header-cell-style="{ background: '#ECECEC', color: '#333' }">
				<el-table-column prop="addtime" label="操作时间" align="center"></el-table-column>
				<el-table-column prop="type" label="类型" width="400" align="center"></el-table-column>
				<el-table-column label="金额" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.realnum >= 0" style="color: #f13232">{{
              scope.row.realnum
            }}</span>
						<span v-else>{{ scope.row.realnum }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="new_money" label="余额" align="center"></el-table-column>
			</el-table>
			<!-- 分页 -->
			<div class="page" v-if="tableData.length > 0">
				<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-size="size" layout="total, prev, pager, next, jumper"
					:total="tableData.length"></el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		pushBalance
	} from "../../../assets/js/personal";
	export default {
		data() {
			return {
				yue: "",
				current: 0,
				current2: 0,
				tabsList2: ["全部", "收入", "支出"],
				currentPage: 1,
				size: 10,
				total: 100,
				pswTipShow: false,
				pswTipShow2: false,
				dialogVisible2: false,
				tableData: [],
				ruleForm: {
					jine: "",
					zhanghu: "",
					mima: "",
				},
				rules: {
					jine: [{
							required: true,
							message: "请输入提现金额",
							trigger: "blur"
						},
						{
							min: 3,
							max: 5,
							message: "长度在 3 到 5 个字符",
							trigger: "blur"
						},
					],
					zhanghu: [{
							required: true,
							message: "请输入支付宝账号",
							trigger: "blur"
						},
						{
							min: 3,
							max: 5,
							message: "长度在 3 到 5 个字符",
							trigger: "blur"
						},
					],
					mima: [{
							required: true,
							message: "请输入支付密码",
							trigger: "blur"
						},
						{
							min: 3,
							max: 5,
							message: "长度在 3 到 5 个字符",
							trigger: "blur"
						},
					],
				},
			};
		},
		mounted() {
			this.getBlance();
		},
		methods: {
			// 获取余额列表
			getBlance() {
				pushBalance({
					user_id: JSON.parse(window.localStorage.getItem("userInfo")) ?
						JSON.parse(window.localStorage.getItem("userInfo")).id :
						"",
					modal: 1,
					offset: this.currentPage,
					limit: this.size,
				}).then((res) => {
					// console.log(res);
					if (res.code == 200) {
						this.yue = res.data.header;
						this.tableData = res.data.list;
					}
				});
			},
			changeCurrent(i) {
				this.current = i;
			},
			submitForm(formName) {
				// console.log(formName);
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$message.success("提现成功！");
						this.dialogVisible2 = false;
						this.ruleForm = {};
					} else {
						this.$message.error("提现失败！");
						return false;
					}
				});
			},
			cancle() {
				this.dialogVisible2 = false;
				this.ruleForm = {};
			},
			changePsw() {
				// console.log(this.ruleForm.oldPsw);
				// if (!this.ruleForm.oldPsw) {
				//   this.pswTipShow = true;
				// }
				// if (this.ruleForm.newPsw !== this.ruleForm.yesPsw) {
				//   this.pswTipShow2 = true;
				// }
			},
			changeCurrent2(i) {
				this.current2 = i;
				let shouru = [];
				let zhichu = [];
				// 收入
				// if (i == 1) {
				//   this.tableData.map((x) => {
				//     if (x.money >= 0) {
				//       shouru.push(x);
				//       console.log(shouru);
				//       this.tableData = shouru;
				//     }
				//   });
				// }
				// 支出
				// else if (i == 2) {
				//   this.tableData.map((x) => {
				//     if (x.money <script 0) {
				//       zhichu.push(x);
				//       console.log(zhichu);
				//       this.tableData = zhichu;
				//     }
				//   });
				// }
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getBlance();
			},
		},
	};
</script>
<style lang="less" scoped>
	.current {
		background-color: #ffffff;
		color: #f13232 !important;
	}

	.current2 {
		width: 60px;
		height: 30px;
		background: rgba(12, 106, 231, 1);
		border-radius: 4px;
		color: #fff;
	}

	.changeMimaTip2 {
		margin-bottom: 5px !important;
	}

	/deep/ .editPaw .el-dialog {
		width: 570px;
		padding: 0;
	}

	/deep/ .editPaw .el-dialog .el-dialog__header {
		height: 77px;
		line-height: 77px;
		font-weight: bold;
		text-align: center;
		border-bottom: 1px solid #ececec;
		padding: 0;
	}

	/deep/ .el-dialog__headerbtn {
		display: none;
	}

	/deep/ .editPaw .el-dialog {
		height: 420px;
		border-radius: 6px;
	}

	/deep/ .editPaw .el-dialog .el-dialog__body {
		padding: 0 40px;
	}

	.editPaw .el-dialog .edit_dailog div:nth-child(1) {
		font-size: 16px;
	}

	.editPaw .el-dialog .edit_dailog div:nth-child(2) {
		background-color: #fff;
		color: #999999;
		font-size: 16px;
	}

	/deep/.el-dialog .el-input {
		width: 340px;
		height: 46px;
		line-height: 46px;
		background: rgba(241, 241, 242, 1);
		border-radius: 4px;
		border: none;
		outline: none;
		padding-left: 15px;
		box-sizing: border-box;
	}

	/deep/ .el-input__inner {
		border: none;
		outline: none;
		background-color: transparent;
		padding: 0;
	}

	/deep/ .el-form-item__error {
		margin-left: 100px;
	}

	/deep/ .el-form {
		padding-left: 20px;
		box-sizing: border-box;
	}

	form {
		margin-top: 25px;
	}

	.editPaw {
		.el-dialog {
			min-width: 570px;
			height: 420px;
			background: rgba(255, 255, 255, 1);
			border-radius: 10px;

			// padding: 20px 40px;
			// box-sizing: border-box;
			.ipt_box {
				font-size: 16px;
				color: #333;
				display: flex;
				align-items: center;
				margin-bottom: 15px;

				.lebal {
					width: 80px;
					margin-right: 15px;
					text-align: right;
				}

				.changeMimaTip {
					font-size: 10px;
					color: #f13232;
					margin-top: 5px;
					position: relative;
					top: 0;
				}
			}

			.changeMimaTip2 {
				margin-bottom: 5px !important;
			}

			.tipShow {
				margin-bottom: 0;
			}

			.el-dialog__footer {
				padding: 0;
			}

			.edit_dailog {
				display: flex;
				align-items: center;
				justify-content: center;

				div {
					color: #999999;
					width: 120px;
					height: 40px;
					border-radius: 20px;
					font-size: 16px;
					line-height: 40px;
					text-align: center;
					cursor: pointer;
				}

				div:nth-child(1) {
					color: #fff;
					background-color: #f13232;
					// margin-left: 80px;
					margin-right: 20px;
				}

				div:nth-child(2) {
					font-size: 16px;
					border: 1px solid #cccccc;
				}
			}
		}
	}

	.yue_body {
		padding: 25px;
		box-sizing: border-box;

		.yue_top {
			display: flex;
			justify-content: space-between;
			// width: 900px;
			height: 180px;
			background: linear-gradient(90deg,
					rgba(239, 111, 25, 1) 0%,
					rgba(245, 69, 69, 1) 99%);
			border-radius: 10px;
			margin-bottom: 40px;
			padding: 35px 22px;
			box-sizing: border-box;

			.yue_left {
				.tit {
					font-size: 18px;
					color: #fff;
				}

				.yue {
					font-size: 44px;
					color: #fff;
					margin-left: 23px;
					margin-top: 15px;
				}
			}

			.tue_right {
				display: flex;
				align-items: center;
				margin-top: 35px;

				.btn {
					width: 120px;
					height: 40px;
					border: 2px solid rgba(255, 255, 255, 1);
					border-radius: 20px;
					font-size: 16px;
					color: #fff;
					text-align: center;
					line-height: 40px;
					margin-right: 20px;
					// cursor: pointer;
					cursor: not-allowed;
				}
			}
		}

		.mingxi_tabs {
			// width: 900px;
			border-bottom: 1px solid rgba(241, 241, 241, 1);
			color: #333;
			padding-bottom: 10px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.ming_left {
				font-size: 16px;
			}

			.ming_right {
				display: flex;

				.mingTabs {
					width: 60px;
					height: 30px;
					cursor: pointer;
					text-align: center;
					line-height: 30px;
				}
			}
		}

		.page {
			// width: 900px;
			margin: 49px auto;
			text-align: center;
		}

		// /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
		//   background-color: #444444;
		//   border-radius: 50%;
		// }
		// /deep/ .el-pagination.is-background .el-pager li {
		//   border-radius: 50%;
		// }
	}
</style>
